<template>
  <div id="speakeasy-bunker-club" class="dark-gradient" :style="`background-size:cover; background-image:url('${require('@/assets/images/suites/your-experience/your-experience-blurred.jpg')}')`">    
      <div class='add-padding'>
        <div class='limit-width'>
            <PageTitle title="Speakeasy Bunker Club"/>
            <div style="position:relative;">
                <div class='your-experience'>
                    <div>
                        <p class="white body bold">Our newest and most exclusive club at Enmarket Arena, only available to 40 total guests.</p>
                        <p class="white body">Prepare to be immersed in a true one-of-a-kind experience.  A private cocktail lounge that pays homage to the south’s rich history by creating an environment similar to that of a traditional speakeasy club.  Whether for personal or business entertainment, treat yourself to the best sight lines and the aura of exclusivity which will create a highly aspirational ambiance.</p>
                        <p class="white body">Enjoy beverages from a beautifully designed top-shelf bar offering up a curated wine selection, hand crafted cocktails and local beers.  Mix and mingle in a related private upholstered lounge area before transitioning from the club to the action in a matter of seconds.</p>
                        <p class="white body bold">Envision this as your own private speakeasy, that only you and your guests know the secret password to.</p>
                    </div>
                    <img src="@/assets/images/suites/speakeasy.jpg" alt="">
                </div>
                <div class='your-experience-cards'>   
                    <div class='cards'>
                        <Card hasHover :card="{icon:'suites/your-experience/tickets.svg',title:'Tickets to Front Seats for All Events' }"/>                        
                        <Card hasHover :card="{icon:'suites/your-experience/parking.svg',title:'Reserved Parking at Ground Level' }"/>                        
                        <Card hasHover :card="{icon:'suites/your-experience/early-access.svg',title:'VIP Tunnel Entrance' }"/>                        
                        <Card hasHover :card="{icon:'suites/your-experience/food.svg',title:'Light Foot & Non-alcoholic Drinks' }"/>
                        <Card hasHover :card="{icon:'suites/your-experience/food.svg',title:'Cash Bar for Beer, Wine & Spirts' }"/>
                        <Card hasHover :card="{icon:'suites/your-experience/hospitality.svg',title:'Private Restrooms' }"/>                        
                        <Card hasHover :card="{icon:'suites/your-experience/private-entrance.svg',title:'Host Private Events on Dark Days' }"/>                        
                    </div>
                </div>
            </div>
        </div> 
      </div> 
    </div> 
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
export default {
    name:'SuitesExperience',
    components: {
      PageTitle,
      Card,
    }
}
</script>

<style lang="scss" scoped>
.bold {
    font-weight: 700;
}
.your-experience {
    display: flex;
    flex-direction: column;
    background: rgba(32, 32, 32, 0.75);
    p {
        padding:15px 30px;
        //width:350px;
    }
    img {
        flex-grow:1;
        object-fit: cover;
        width:100%;
    }
}
.your-experience-cards {
    margin-top:30px;
    padding-bottom:115px;
    .cards {
        display: grid;
        gap:15px;
        .card {
            width:100%;
            height:175px;            
        }
    }
}
@media (min-width:1000px) {
    .your-experience {
    flex-direction: row;
    p {
        width:350px;
    }
    img {
        width: 50%;
    }
  }
}
@media (min-width:725px) {
  
  .your-experience-cards .cards {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
  
}
</style>