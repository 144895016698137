<template>
  <div>
    <transition name="slide-fade" mode="out-in" appear>
      <SubNav :items="activePages"/>
    </transition>
    <div class='main'>
      <Component v-for="page in activePages" :key="page.id" :is="page.component" />        
      <Footer />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { shallowRef } from 'vue'

import SubNav from '@/components/SubNav';
import Footer from '@/components/Footer';
import SuitesPhotos from '@/pages/SuitesPhotos';
import SuitesExperience from '@/pages/SuitesExperience';
import SuitesMap from '@/pages/SuitesMap';
import SpeakEasyBunkerClub from '@/pages/SpeakEasyBunkerClub';
//import PaymentSchedule from '@/pages/PaymentSchedule';

const suitesPhotos = shallowRef(SuitesPhotos)
const suitesExperience = shallowRef(SuitesExperience)
const suitesMap = shallowRef(SuitesMap)
const speakEasy = shallowRef(SpeakEasyBunkerClub)
export default {
  name: 'Suites',
  components: {
      SubNav,      
      Footer        
  },
  mounted() {
      if(this.$store.state.preview) {
        return;
      }
      const post = {
        method:"page",
        purl:this.$store.state.purl,
        sessionDbId:this.$store.state.sessionDbId,
        section:'4'
      }
      axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post);     
  },
  computed: {
    activePages() {
        let serverPages = this.$store.state.pages.filter(serverPage => {      
            return this.pages.findIndex(page => serverPage.id === page.id) === -1 ? false : true;                
        });
        let pages = [];
        serverPages.forEach(element => {
          pages.push({...element, ...this.pages[this.pages.findIndex(page => element.id === page.id)]});
          
        });
        return pages;
    }
  },
  data: () => ({         
      pages : [
          {
            id : '22',
            path : '/suites',
            hash : 'suite-photos',
            name : 'Suite Photos',
            parentName:'Suites',
            component: suitesPhotos
          },
          {
            id : '23',
            path : '/suites',
            hash : 'your-experience',
            name : 'Your Experience',
            parentName:'Suites',
            component: suitesExperience
          },
          {
            id : '24',
            path : '/suites',
            hash : 'seat-map-pricing',
            name : 'Seat Map & Pricing',
            parentName:'Suites',
            component: suitesMap
          },  
          {
            id : '30',
            path : '/suites',
            hash : 'speakeasy-bunker-club',
            name : 'Speakeasy Bunker Club',
            parentName:'Suites',
            component: speakEasy
          },  
                        
      ] 
  }),
}
</script>