<template>
  <div id="your-experience" class="dark-gradient" :style="`background-size:cover; background-image:url('${require('@/assets/images/suites/your-experience/your-experience-blurred.jpg')}')`">    
      <div class='add-padding'>
        <div class='limit-width'>
            <PageTitle title="Experience It All"/>
            <div style="position:relative;">
                <div class='your-experience'>
                    <p class="white body">Enmarket Arena suites provide an exclusive VIP experience to accommodate 14 people in a private lounge – a private meeting place for your guests before, during, and after the event – with the luxury you’d expect from a world-class venue.   Each suite is designed to foster a sense of community with luxury seating and access to purchase custom food and beverage packages.</p>
                    <img src="@/assets/images/suites/your-experience/experience-it-all.jpg" alt="">
                </div>
                <div class='your-experience-cards'>   
                    <div class='cards'>
                        <Card hasHover :card="{icon:'suites/your-experience/tickets.svg',title:'Tickets to All Events' }"/>                        
                        <Card hasHover :card="{icon:'suites/your-experience/parking.svg',title:'VIP Parking Passes' }"/>                        
                        <Card hasHover :card="{icon:'suites/your-experience/early-access.svg',title:'Early Access' }"/>                        
                        <Card hasHover :card="{icon:'suites/your-experience/food.svg',title:'Customized F&B package available for purchase' }"/>
                        <Card hasHover :card="{icon:'suites/your-experience/hospitality.svg',title:'Hospitality Service' }"/>                        
                        <Card hasHover :card="{icon:'suites/your-experience/private-entrance.svg',title:'Private Entrance' }"/>                        
                    </div>
                </div>
            </div>
        </div> 
      </div> 
    </div> 
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
export default {
    name:'SuitesExperience',
    components: {
      PageTitle,
      Card,
    }
}
</script>

<style lang="scss" scoped>
.your-experience {
    display: flex;
    background: rgba(32, 32, 32, 0.75);
    p {
        padding:30px;
        width:260px;
    }
    img {
        flex-grow:1;
        object-fit: cover;
        width:50%;
    }
}
.your-experience-cards {
    margin-top:30px;
    padding-bottom:115px;
    .cards {
        display: grid;
        gap:15px;
        .card {
            width:100%;
            height:175px;            
        }
    }
}
@media (min-width:725px) {
  .your-experience-cards .cards {
    grid-template-columns: repeat(auto-fit, minmax(185px, 1fr));
  }
  
}
</style>