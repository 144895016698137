<template>
  <div id="seat-map-pricing" class="light-gradient" :style="`background-size:cover; background-image:url('${require('@/assets/images/suites/suite-map/background.jpg')}')`">    
    <div class='add-padding'>
        <div class='limit-width'>
            <PageTitle dark title="Pricing & Layout"/>
            <div class="suites-map">
                <MapViews path="suites/suite-map" :views="[{label:'Live Entertainment View', image:'arena'},{label:'Hockey View', image:'arena-hockey'}]"/>          
                <div class="suites">              
                    <svg class='svg-map' width="55" height="835" viewBox="0 0 55 835" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g @click="activeSlide === 0 ? activeSlide = -1 : activeSlide = 0;" :class="[activeSlide == 0 ? 'active' : '']">
                            <path class='hotspot' fill="#707070" d="M2.6.999h50v34h-50z"/>
                            <path d="M25.308 20.794c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm8.156 1.104v-8.004H32.24l-2.304 2.328.816.852 1.308-1.368v6.192h1.404z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 1 ? activeSlide = -1 : activeSlide = 1;" :class="[activeSlide == 1 ? 'active' : '']">
                            <path class='hotspot' fill="#707070" d="M2.6 36.998h50v34h-50z"/>
                            <path d="M25.308 56.794c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm10.844 1.104v-1.236h-3.54c2.136-1.62 3.492-2.94 3.492-4.428 0-1.596-1.344-2.46-2.892-2.46-1.128 0-2.256.42-2.964 1.284l.816.912c.516-.576 1.224-.948 2.16-.948.732 0 1.464.372 1.464 1.212 0 1.164-1.224 2.196-4.332 4.548v1.116h5.796z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 2 ? activeSlide = -1 : activeSlide = 2;" :class="[activeSlide == 2 ? 'active' : '']">
                            <path class='hotspot' fill="#619328" d="M2.6 72.999h50v34h-50z"/>
                            <path d="M25.308 92.794c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm7.76 1.248c1.86 0 3.024-.972 3.024-2.34 0-1.236-1.092-1.86-1.86-1.944.816-.132 1.728-.756 1.728-1.836 0-1.332-1.152-2.148-2.88-2.148-1.332 0-2.304.528-2.928 1.26l.72.876c.576-.588 1.248-.888 2.076-.888.888 0 1.62.384 1.62 1.116 0 .732-.708 1.056-1.656 1.056-.312 0-.78 0-.912-.012v1.272c.12-.012.576-.024.912-.024 1.152 0 1.776.348 1.776 1.14 0 .732-.612 1.224-1.668 1.224-.852 0-1.692-.372-2.208-.972l-.768.924c.552.72 1.608 1.296 3.024 1.296z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 3 ? activeSlide = -1 : activeSlide = 3;" :class="[activeSlide == 3 ? 'active' : '']">
                            <path class='hotspot' fill="#707070" d="M2.6 108.999h50v34h-50z"/>
                            <path d="M25.308 128.794c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm10.004 1.104v-1.812h1.068v-1.236h-1.068v-4.956H33.38l-3.288 5.064v1.128h3.816v1.812h1.404zm-1.404-3.048h-2.436l2.436-3.696v3.696z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 4 ? activeSlide = -1 : activeSlide = 4;" :class="[activeSlide == 4 ? 'active' : '']">
                            <path class='hotspot' fill="#707070" d="M2.6 144.999h50v34h-50z"/>
                            <path d="M25.308 164.794c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm8.096 1.248c1.788 0 3.06-1.056 3.06-2.712 0-1.644-1.212-2.58-2.568-2.58-.768 0-1.416.312-1.812.684v-2.304h3.84v-1.236H30.68v4.464l.996.288c.516-.48 1.08-.684 1.752-.684.984 0 1.608.564 1.608 1.416 0 .792-.624 1.416-1.644 1.416-.888 0-1.62-.336-2.148-.96l-.804.972c.648.756 1.596 1.236 2.964 1.236z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 5 ? activeSlide = -1 : activeSlide = 5;" :class="[activeSlide == 5 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 267.999h50v43h-50z"/>
                            <path d="M25.308 293.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm8.156 1.104v-8.004H32.24l-2.304 2.328.816.852 1.308-1.368v6.192h1.404z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 6 ? activeSlide = -1 : activeSlide = 6;" :class="[activeSlide == 6 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 312.999h50v43h-50z"/>
                            <path d="M25.308 338.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm10.844 1.104v-1.236h-3.54c2.136-1.62 3.492-2.94 3.492-4.428 0-1.596-1.344-2.46-2.892-2.46-1.128 0-2.256.42-2.964 1.284l.816.912c.516-.576 1.224-.948 2.16-.948.732 0 1.464.372 1.464 1.212 0 1.164-1.224 2.196-4.332 4.548v1.116h5.796z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 7 ? activeSlide = -1 : activeSlide = 7;" :class="[activeSlide == 7 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 357.999h50v43h-50z"/>
                            <path d="M25.308 383.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm7.76 1.248c1.86 0 3.024-.972 3.024-2.34 0-1.236-1.092-1.86-1.86-1.944.816-.132 1.728-.756 1.728-1.836 0-1.332-1.152-2.148-2.88-2.148-1.332 0-2.304.528-2.928 1.26l.72.876c.576-.588 1.248-.888 2.076-.888.888 0 1.62.384 1.62 1.116 0 .732-.708 1.056-1.656 1.056-.312 0-.78 0-.912-.012v1.272c.12-.012.576-.024.912-.024 1.152 0 1.776.348 1.776 1.14 0 .732-.612 1.224-1.668 1.224-.852 0-1.692-.372-2.208-.972l-.768.924c.552.72 1.608 1.296 3.024 1.296z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 8 ? activeSlide = -1 : activeSlide = 8;" :class="[activeSlide == 8 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 402.999h50v43h-50z"/>
                            <path d="M25.308 428.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm10.004 1.104v-1.812h1.068v-1.236h-1.068v-4.956H33.38l-3.288 5.064v1.128h3.816v1.812h1.404zm-1.404-3.048h-2.436l2.436-3.696v3.696z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 9 ? activeSlide = -1 : activeSlide = 9;" :class="[activeSlide == 9 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 447.999h50v62h-50z"/>
                            <path d="M25.308 482.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm8.096 1.248c1.788 0 3.06-1.056 3.06-2.712 0-1.644-1.212-2.58-2.568-2.58-.768 0-1.416.312-1.812.684v-2.304h3.84v-1.236H30.68v4.464l.996.288c.516-.48 1.08-.684 1.752-.684.984 0 1.608.564 1.608 1.416 0 .792-.624 1.416-1.644 1.416-.888 0-1.62-.336-2.148-.96l-.804.972c.648.756 1.596 1.236 2.964 1.236z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 10 ? activeSlide = -1 : activeSlide = 10;" :class="[activeSlide == 10 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 511.999h50v62h-50z"/>
                            <path d="M25.308 546.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm8.252 1.248c1.788 0 2.952-1.212 2.952-2.688 0-1.704-1.248-2.592-2.688-2.592-.936 0-1.776.588-2.148 1.128v-.228c0-1.404.768-2.64 2.112-2.64.756 0 1.224.264 1.644.696l.648-1.056c-.54-.54-1.308-.888-2.292-.888-2.316 0-3.528 1.824-3.528 4.128 0 2.232.948 4.14 3.3 4.14zm-.084-1.248c-1.224 0-1.704-1.02-1.776-1.884a2.164 2.164 0 011.752-.936c.864 0 1.632.444 1.632 1.428 0 .636-.552 1.392-1.608 1.392z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 11 ? activeSlide = -1 : activeSlide = 11;" :class="[activeSlide == 11 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 575.999h50v43h-50z"/>
                            <path d="M25.308 601.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm7.448 1.104l3.096-7.032v-.972H30.08v1.236h4.152l-3.024 6.768h1.548z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 12 ? activeSlide = -1 : activeSlide = 12;" :class="[activeSlide == 12 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 620.999h50v43h-50z"/>
                            <path d="M25.308 646.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm8.024 1.248c1.632 0 3.072-.768 3.072-2.208 0-1.008-.828-1.74-1.8-2.064.9-.288 1.668-.888 1.668-1.896 0-1.488-1.524-2.1-2.94-2.1s-2.94.612-2.94 2.1c0 1.008.768 1.608 1.668 1.896-.972.324-1.8 1.056-1.8 2.064 0 1.44 1.428 2.208 3.072 2.208zm0-4.836c-.48-.084-1.512-.372-1.512-1.116 0-.684.648-1.068 1.512-1.068s1.512.384 1.512 1.068c0 .744-1.02 1.032-1.512 1.116zm0 3.588c-.912 0-1.644-.432-1.644-1.14 0-.828 1.14-1.188 1.644-1.26.492.072 1.644.432 1.644 1.26 0 .708-.756 1.14-1.644 1.14z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 13 ? activeSlide = -1 : activeSlide = 13;" :class="[activeSlide == 13 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 665.999h50v43h-50z"/>
                            <path d="M25.308 691.143c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892zm7.64 1.236c2.316 0 3.528-1.824 3.528-4.14 0-2.22-.948-4.128-3.3-4.128-1.788 0-2.952 1.2-2.952 2.688 0 1.704 1.236 2.58 2.676 2.58.948 0 1.788-.576 2.148-1.116.012.072.012.156.012.228 0 1.308-.66 2.64-2.112 2.64-.768 0-1.224-.264-1.644-.708l-.66 1.068c.54.528 1.308.888 2.304.888zm.336-4.2c-.876 0-1.644-.444-1.644-1.428 0-.648.552-1.392 1.608-1.392 1.236 0 1.704 1.008 1.788 1.872-.408.576-1.068.948-1.752.948z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 14 ? activeSlide = -1 : activeSlide = 14;" :class="[activeSlide == 14 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 710.999h50v43h-50z"/>
                            <path d="M25.308 735.999v-8.004h-1.224l-2.304 2.328.816.852 1.308-1.368v6.192h1.404zm5.309.144c2.244 0 3.192-2.136 3.192-4.14 0-2.016-.948-4.128-3.192-4.128-2.256 0-3.204 2.112-3.204 4.128 0 2.004.948 4.14 3.204 4.14zm0-1.248c-1.308 0-1.776-1.368-1.776-2.892 0-1.536.468-2.88 1.776-2.88s1.764 1.344 1.764 2.88c0 1.524-.468 2.892-1.764 2.892z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 15 ? activeSlide = -1 : activeSlide = 15;" :class="[activeSlide == 15 ? 'active' : '']">                            
                            <path class='hotspot' fill="#707070" d="M2.6 755.999h50v39h-50z"/>
                            <path d="M25.308 778.51v-8.004h-1.224l-2.304 2.328.816.852 1.308-1.368v6.192h1.404zm5.309 0v-8.004h-1.224l-2.304 2.328.816.852 1.308-1.368v6.192h1.404z" fill="#fff"/>
                        </g>
                        <g @click="activeSlide === 16 ? activeSlide = -1 : activeSlide = 16;" :class="[activeSlide == 16 ? 'active' : '']">                        
                            <path class='hotspot' fill="#707070" d="M2.6 796.999h50v38h-50z"/>
                            <path d="M25.308 819.138v-8.004h-1.224l-2.304 2.328.816.852 1.308-1.368v6.192h1.404zm7.997 0v-1.236h-3.54c2.136-1.62 3.492-2.94 3.492-4.428 0-1.596-1.344-2.46-2.892-2.46-1.128 0-2.256.42-2.964 1.284l.816.912c.516-.576 1.224-.948 2.16-.948.732 0 1.464.372 1.464 1.212 0 1.164-1.224 2.196-4.332 4.548v1.116h5.796z" fill="#fff"/>
                        </g>                                                
                    </svg>
                </div>
                <div class='ui'>
                    <div class='suite' v-for="(suite, id) in suites" :key="suite.title" :class="[id=== 4 ? 'space': '', activeSlide == id ? 'active' : '']">
                        <div>
                            <button @click="activeSlide === id ? activeSlide = -1 : activeSlide = id;" class='body white'>
                                <p>{{suite.title}} <span class="available">({{suite.available}})</span></p>                    
                                <svg width="7" height="15" viewBox="0 0 7 12" fill="none">
                                    <path d="M1.625 1.125L6 5.92683L1.625 10.5" stroke="#E43141" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div class="info body">
                                <div class="body white" v-if="suite.capacity">{{suite.capacity}} Seats</div>                        
                                <div class="body white" v-if="suite.cost !== 0">${{toCurrency(suite.cost)}} Annually</div>                        
                                <div class="body white caps uppercase">{{suite.sold ? 'Sold' : 'Available'}}</div>                        
                            </div>
                        </div>            
                    </div>            
                </div>
            </div>           
        </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import MapViews from '@/components/MapViews';
export default {
    name:'SuitesMap',
    components: {        
        PageTitle,
        MapViews
    },    
    methods: {
         toCurrency: function (value) {
            if (typeof value !== "number") {
               return value;
            }
                var formatter = new Intl.NumberFormat("en-US", {        
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }
            );
            return formatter.format(value);
        },
        galleryUpdatedSlide(value) {
            this.activeSlide = value;
        }
    },
    data: () => ({   
      activeSlide:0,
      suites: [
          {
              title:'Mini Suite 1',
              available:'Hockey Only',
              capacity:'8',
              cost:40000,
              sold:true,
          }, 
          {
              title:'Mini Suite 2',
              available:'Hockey Only',
              capacity:'8',
              cost:40000,
              sold:true,
          }, 
          {
              title:'Mini Suite 3',
              available:'Hockey Only',
              capacity:'6',
              cost:30000,
          }, 
          {
              title:'Mini Suite 4',
              available:'All Events',
              cost:0,
              sold:true        
          }, 
          {
              title:'Mini Suite 5',
              available:'All Events',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 1',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 2',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 3',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true        
          }, 
          {
              title:'Suite 4',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true     
          }, 
          {
              title:'Suite 5',
              available:'All Events',
              capacity:'24',
              cost:0,
              sold:true          
          }, 
          {
              title:'Suite 6',
              available:'All Events',
              capacity:'24',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 7',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 8',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 9',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 10',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 11',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true
          }, 
          {
              title:'Suite 12',
              available:'All Events',
              capacity:'14',
              cost:0,
              sold:true
          },          
      ]
  }),
}
</script>

<style lang="scss" scoped>
#seat-map-pricing {
    padding-bottom:50px;
}
.available {
    font-size:0.75rem;
    text-transform: none;
}
.suites {
    margin-left:15px;
}
.suite.space {
    margin-bottom:15px;
}
button {
    border:none;    
    width:100%;
    text-align: left;
    padding:10px;
    color:#FFFFFF;
    text-transform: uppercase;
    background: rgba(32, 32, 32, 0.75);
    display: flex;
    justify-content:space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
    svg {
        transform: rotateZ(90deg);
        transition: transform 0.25s cubic-bezier(1, 0.5, 0.8, 1);
    }
}
.svg-map {
    margin-right:30px;
    background-size:contain;
}
g {
    cursor: pointer;
    path {
        transition: fill 0.25s cubic-bezier(1, 0.5, 0.8, 1);        
    }
    &.active {
        path {
            &.hotspot {
                fill:#E43141;
            }
        }        
    }
}
.suites-map {
    position: relative;
    display:flex;     
    > img {
        margin-right:65px;
        object-fit: contain;
        flex-grow: 1;
        max-width: 489px;
        flex-shrink: 1;
        width: 50%;
        object-position: top;        
    }   
}
@media (max-width:900px) {
    .suites-map > img {
        display:none;
    }
    
}
.active {
    button {
        background:#E43141;     
        color:#fff;   
    }
    svg {        
        transform: rotateZ(270deg);
        path {
            stroke:#fff;
        }
    }
    .info {
        max-height:120px;
    }
}
.info {
    background: rgba(32, 32, 32, 0.75);
    text-align: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.25s cubic-bezier(1, 0.5, 0.8, 1);
    .uppercase {
        text-transform: uppercase;
    }
    > div {
        padding:10px;        
    }
}
.ui {    
    flex-grow: 1;
    min-width: 200px;
}
</style>