<template>
    <div class='views'>
        <div class="ui">
            <button  v-for="view in views" :key="view.image" :class="[map === view.image ? 'active' : '']" @click="map = view.image">{{view.label}}</button>
        </div>
        <div class='map-image'>
            <transition name="fade" v-for="view in views" :key="view.image">
                <img appear v-show="map === view.image" :src="require(`@/assets/images/${path}/${view.image}.png`)" alt="">
            </transition>
        </div>                    
    </div>
</template>

<script>
export default {
    name:'MapView',
    data: () => ({   
      map:'club-seating-map'
    }),
    props : {
        views:Array,
        path:String,
    },
    created() {
        this.map = this.views[0].image;
    }
}
</script>

<style lang="scss" scoped>
.views {
    flex-grow: 1;
}
.map-image {
    position:relative;
    height:600px;
    img {
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height: 100%;
            object-fit: contain;
        
    }    
}
.ui {
    display:flex;
    gap:15px;
    margin-bottom: 25px;
    button {
        flex-grow: 1;
        background:none;
        outline:none;
        border:none;
        background: rgba(32, 32, 32, 0.75);
        color:#fff;
        font-size: 1rem;
        padding:10px;
        width:50%;
        transition: background 0.25s cubic-bezier(1, 0.5, 0.8, 1);
        cursor: pointer;
        &.active, &:hover {
            background: #D84350;
        }
    }
}
</style>