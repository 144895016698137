<template>
  <div class="top" id="suite-photos">  
      <Gallery :images="[
        {file:'suites/top/Suites-Hero-GalleryImage1.jpg', title: 'title'},
        {file:'suites/top/Suites-Hero-GalleryImage2.jpg', title: 'title'},
        {file:'suites/top/Suites-Hero-GalleryImage3.jpg', title: 'title'},
        {file:'suites/top/Suites-Hero-GalleryImage4.jpg', title: 'title'},
      ]"/>
      <div class='limit-width'>
        <transition name="slide-fade" mode="out-in" appear>
              <div>
                <h1 class="white">Enjoy The Premium Experience In One Of Our Suites</h1>
                <div class='cta'>
                  <Button text='Sign up' secondary @click="$store.state.showSignup = true; $store.state.blockScroll = true;"/>
                </div>
              </div> 
          </transition>
      </div>
    </div>
</template>

<script>
import Gallery from '@/components/Gallery';
import Button from '@/components/Button';
export default {
    name:'SuitesPhotos',
    components: {
        Gallery,
        Button
    }
}
</script>

<style lang="scss" scoped>
.top {
    h1 {
      max-width: 650px;
      margin-top:22px;
      margin-bottom:12px;
    }      
    .cta {
      margin-top: 20px;
    }
  }
</style>